// Components
import { ProofList } from 'src/components/folders';
import { useRemoteProofs } from 'src/features/lastRemoteProofs';
import { useProofsFilteredByEntryId } from 'src/features/proofs/hooks';
// Hooks
import { useEffect } from 'react';
import { useFolderPage } from '../FolderPageContext';

export const ProofListContainer = () => {
  const { updateLastRemoteProofs } = useRemoteProofs();
  const { selectedEntryId } = useFolderPage();
  const { proofs, isLoading } = useProofsFilteredByEntryId(selectedEntryId as string);

  useEffect(() => {
    if (proofs) {
      updateLastRemoteProofs(proofs);
    }
  }, [isLoading]);

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  return <ProofList proofs={proofs} />;
};
