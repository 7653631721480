import { useDispatch } from 'react-redux';
import { canSelectDocType } from 'src/features/myWork/helpers';
import {
  RESULT_CLEAN_PROCEED_WITH_CAUTION_REASON_LABEL,
  RESULT_INSUFFICIENT_REASON_LABEL
} from 'src/features/proofs';
import { IFormValues } from 'src/pages/ReviewPage/components/BulkSelectDoc/BulkSelectDoc';
import { EntryResultEnum } from 'src/ts/enums';
import { IProof } from 'src/ts/interfaces';
import { actions } from '../proofsSlice';
import { updateProofThunk } from '../services';

export const useProofUpdateState = () => {
  const dispatch = useDispatch();

  const onCheckProof = (proofId: string, isChecked: boolean) => {
    dispatch(actions.onUpdateCheckProof({ proofId, isChecked }));
  };

  const onCheckProofs = (isChecked: boolean | null) => {
    dispatch(actions.onUpdateCheckProofs({ isChecked: Boolean(isChecked) }));
  };

  const onUpdateLocalProofState = (id: string, formValues: IFormValues) => {
    dispatch(actions.updateLocalProofState({ id, formValues }));
  };

  const getCheckedAll = (proofs: IProof[]) => {
    const unChecked = proofs.length;
    const checked = proofs.filter((proof) => proof.isChecked).length;

    if (checked) {
      if (checked === unChecked) return true;
      if (checked < unChecked) return null;
    }
    return false;
  };

  const onUpdateSelected = (formValues: IFormValues, proofs: IProof[]) => {
    const data = proofs.filter((proof) => proof.isChecked);
    const canSaveDocType = canSelectDocType(
      formValues?.result,
      formValues.resultInsufficientReason
    );
    const type = canSaveDocType ? { type: formValues?.document } : {};
    const result = formValues?.result ? { result: formValues?.result } : {};

    let note = '';
    // Add the reason explanation to the document note when insufficient reason or clean proceed with caution is selected
    if (
      formValues?.result === EntryResultEnum.Undetermined &&
      formValues?.resultInsufficientReason
    ) {
      note = RESULT_INSUFFICIENT_REASON_LABEL[formValues.resultInsufficientReason];
    } else if (
      formValues?.result === EntryResultEnum.CleanProceedWithCaution &&
      formValues?.resultCleanProceedWithCautionReason
    ) {
      note =
        RESULT_CLEAN_PROCEED_WITH_CAUTION_REASON_LABEL[
          formValues.resultCleanProceedWithCautionReason
        ];
    }

    data.forEach((item) => {
      dispatch(actions.setLoadingProof({ proofId: item.id, isLoading: true }));
      dispatch(
        updateProofThunk({
          id: item.id,
          note,
          result_clean_proceed_with_caution_reason: formValues?.resultCleanProceedWithCautionReason,
          result_insufficient_reason: formValues?.resultInsufficientReason,
          result_edited_reason: formValues.resultEditedReason,
          ...result,
          ...type
        })
      );
    });
    onCheckProofs(false);
  };

  const getIsLoading = (proofs: IProof[]) => {
    return proofs.filter((proof) => proof.isLoading).length > 0;
  };

  const getURLProofs = (proofs: IProof[]) => {
    return proofs.map((proof) => {
      return proof.file;
    }) as string[];
  };

  return {
    onCheckProof,
    onCheckProofs,
    getCheckedAll,
    onUpdateSelected,
    getIsLoading,
    getURLProofs,
    onUpdateLocalProofState
  };
};
