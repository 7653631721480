// Vendor
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';

// Components
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import ButtonIcon, { ButtonIconBackgroundColor } from 'src/components/molecules/ButtonIcon';
import {
  Background as StyleBackground,
  Body as StyleBody,
  Container as StyleContainer,
  Content as StyleContent,
  Footer as StyleFooter,
  Header as StyleHeader,
  Wrapper as StyleWrapper,
  StyledText
} from './styles';

export enum ModalVariant {
  none = 'none',
  primary = 'primary',
  danger = 'danger'
}

export enum ModalWidth {
  large = 'large',
  small = 'small',
  extraLarge = 'extraLarge'
}

export type ModalProps = {
  title: string;
  labelButtonConfirm?: string;
  labelButtonCancel?: string;
  isLoadingButtonConfirm?: boolean;
  isDisabledButtonConfirm?: boolean;
  isDisabledButtonCancel?: boolean;
  children?: React.ReactNode;
  text?: string;
  variant: ModalVariant;
  width?: ModalWidth;
  showModal: boolean;
  isBackClosable?: boolean;
  blurBackground?: boolean;
  isEscapeClosable?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  showModalActions?: boolean;
  className?: string;
  onConfirm?: (value?: any) => void | (() => Promise<void>) | Promise<void>;
  onCancel?: () => void;
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    showModal,
    setShowModal,
    children,
    title,
    text,
    variant,
    width = ModalWidth.large,
    isBackClosable,
    blurBackground,
    isEscapeClosable,
    labelButtonConfirm,
    labelButtonCancel,
    isLoadingButtonConfirm,
    isDisabledButtonConfirm,
    isDisabledButtonCancel,
    showCloseButton,
    showCancelButton,
    showModalActions,
    className,
    onConfirm,
    onCancel
  } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  });

  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    if (modalRef.current === e.target && isBackClosable) {
      onCanceling();
    }
  };

  const keyPress = useCallback(
    (e: { key: string }) => {
      if (e.key === 'Escape' && showModal && isEscapeClosable) {
        onCanceling();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal, showModal, isEscapeClosable]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  const getColorButton = () => {
    switch (variant) {
      case ModalVariant.danger:
        return ButtonColor.cancel;

      default:
        return ButtonColor.primary;
    }
  };

  const onCanceling = () => {
    onCancel?.();
    setShowModal?.(false);
  };

  if (!text && !children)
    throw new Error('The Modal must have a "Text" prop or "Children" content');

  return (
    <>
      {showModal ? (
        <StyleBackground
          className={className}
          onClick={closeModal}
          ref={modalRef}
          blurBackground={blurBackground}
        >
          <animated.div style={animation} className="modal__container">
            <StyleWrapper variant={variant} width={width}>
              <StyleContent>
                <StyleHeader>
                  <Text color={TextColor.initial} variant={TextVariant.h3}>
                    {title}
                  </Text>
                  {showCloseButton && (
                    <ButtonIcon
                      name="button-close"
                      icon="close"
                      background={ButtonIconBackgroundColor.none}
                      iconColor="muted"
                      onClick={() => onCanceling()}
                    />
                  )}
                </StyleHeader>
                <StyleBody>
                  {text ? (
                    <StyledText color={TextColor.gray600} variant={TextVariant.normal}>
                      {text}
                    </StyledText>
                  ) : (
                    <StyleContainer>{children}</StyleContainer>
                  )}
                </StyleBody>
                {showModalActions && (
                  <StyleFooter showCancelButton={showCancelButton} className="Buttons">
                    <Button
                      onClick={() => onConfirm?.()}
                      size={ButtonSize.medium}
                      name={`${labelButtonConfirm}_button_confirm_modal`}
                      label={labelButtonConfirm}
                      color={getColorButton()}
                      variant={ButtonVariant.contained}
                      isDisabled={isDisabledButtonConfirm}
                      isLoading={isLoadingButtonConfirm}
                    >
                      {labelButtonConfirm}
                    </Button>
                    {showCancelButton && (
                      <Button
                        onClick={() => onCanceling()}
                        size={ButtonSize.medium}
                        variant={ButtonVariant.outline}
                        name={`${labelButtonCancel}_button_cancel_modal`}
                        label={labelButtonCancel}
                        color={ButtonColor.primary}
                        isDisabled={isDisabledButtonCancel}
                      >
                        {labelButtonCancel}
                      </Button>
                    )}
                  </StyleFooter>
                )}
              </StyleContent>
            </StyleWrapper>
          </animated.div>
        </StyleBackground>
      ) : null}
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  /**
   *  Closes the modal when the background is clicked
   */
  isBackClosable: PropTypes.bool,

  /**
   *  Closes the modal when escape key is pressed
   */
  isEscapeClosable: PropTypes.bool,
  labelButtonConfirm: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  isLoadingButtonConfirm: PropTypes.bool,
  isDisabledButtonConfirm: PropTypes.bool,
  isDisabledButtonCancel: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf<ModalVariant>(Object.values(ModalVariant)).isRequired,
  width: PropTypes.oneOf<ModalWidth>(Object.values(ModalWidth)),
  showCloseButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  showModalActions: PropTypes.bool,
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
};

Modal.defaultProps = {
  variant: ModalVariant.none,
  width: ModalWidth.large,
  labelButtonConfirm: 'Confirm',
  labelButtonCancel: 'Cancel',
  isBackClosable: true,
  isEscapeClosable: false,
  showCloseButton: false,
  showCancelButton: true,
  showModalActions: true,
  className: ''
};

export default memo(Modal);
