// Vendor
import { FC } from 'react';

// Components
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ProofListContainer } from 'src/components/folders/containers';
import { AutoSavingNoteForm } from 'src/components/molecules/AutoSavingNoteForm';
import { ShowState } from 'src/components/molecules/ShowState';
import EntriesMultiTabView from 'src/components/organisms/EntriesMultiTabView';
import { EntryStatusBar } from '../EntryStatusBar';
import { MetadataModal } from '../MetadataModal';
import { SendReportModal, SplitEntryModal } from '../modals';
import { RequestDocumentsModal } from '../modals/RequestDocumentsModal';

// Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useEntryUpdateNote, useRequestDocuments } from 'src/features/entries/hooks';
import { useCalculationDispute } from 'src/features/incomeVerification/hooks';
import { useProofById } from 'src/features/proofs/hooks';
import { useFeatureFlag } from 'src/hooks';
import { useFolderPage } from '../FolderPageContext';

// Types
import { SubmissionsTabContentProps, SubmissionsTabProps } from './types';

import { FeatureFlagKey } from 'src/ts/enums';
import { IProof } from 'src/ts/interfaces';
import SendReportFraudModal from '../modals/SendReportFraudModal';

export const EntryListContent: FC<SubmissionsTabContentProps> = ({
  entry,
  isButtonDisabled,
  isAccountRepresentative
}) => {
  const { setShowSplitEntryModal } = useFolderPage();
  const { onUpdateNote } = useEntryUpdateNote();
  const { onDisputeCalculation, isDisputeLoading } = useCalculationDispute(entry?.folder_id);
  return (
    <Row direction="column" gap={1}>
      <Row.Col>
        <EntryStatusBar
          result={entry?.result}
          income={entry?.income_calculation}
          suggestedRuling={entry?.suggested_ruling}
          disableButton={isButtonDisabled}
          onRemoveFromFolder={() => setShowSplitEntryModal?.(true)}
          isDisputesButtonVisible={entry?.income_verification_disputable}
          onIncomeCalculationDispute={async () => await onDisputeCalculation(entry?.id)}
          isDisputesButtonDisabled={isDisputeLoading}
        />
      </Row.Col>
      <Row.Col>
        <Row gap={1}>
          <Row.Col>
            <Text variant={TextVariant.h4}>Application notes</Text>
          </Row.Col>
          <Row.Col>
            <AutoSavingNoteForm
              name="entryNote"
              placeholder="Application notes here"
              defaultValue={entry?.note}
              isReadonly={isAccountRepresentative}
              onSubmit={(value) => onUpdateNote?.(entry.id, value?.entryNote)}
            />
          </Row.Col>
        </Row>
      </Row.Col>
      <Row.Col>
        <ProofListContainer />
      </Row.Col>
    </Row>
  );
};

const EntryList: FC<Pick<SubmissionsTabProps, 'entries'>> = ({ entries }) => {
  const { onChangeSelectedEntry: handleSelectedSubmissionsTab } = useFolderPage();
  const { isAccountRepresentative } = useUserRoles();
  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  if (!entries?.length) {
    return (
      <Card>
        <Gap height={2.75} />
        <ShowState
          variant="empty"
          type="application"
          message="There are no applications <br /> available to show"
        />
      </Card>
    );
  }

  return (
    <EntriesMultiTabView
      entries={entries}
      cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
      onTabSelect={(index) => handleSelectedSubmissionsTab?.(entries?.[index]?.id)}
      tabContentChildren={(entry) => (
        <EntryListContent
          entry={entry}
          isButtonDisabled={entries?.length === 1}
          isAccountRepresentative={isAccountRepresentative ?? false}
        />
      )}
    />
  );
};

const MetadataModalContainer = () => {
  const { selectedProofId, showMetadataModal, setShowMetadataModal } = useFolderPage();
  const { proof } = useProofById(selectedProofId as string);
  return (
    <MetadataModal
      extracted_meta={proof?.extracted_meta}
      test_meta_data_flags={proof?.test_meta_data_flags}
      showModal={showMetadataModal}
      setShowModal={setShowMetadataModal}
    />
  );
};

const EntryModals = () => {
  const { selectedEntryId, showRequestDocumentsModal, setShowRequestDocumentsModal } =
    useFolderPage();
  const { onRequestDocuments } = useRequestDocuments();
  const ivSupportForFraudDisputesFlagEnabled = useFeatureFlag(
    FeatureFlagKey.IV_SUPPORT_FOR_FRAUD_DISPUTES
  );
  return (
    <>
      <MetadataModalContainer />
      <RequestDocumentsModal
        showModal={showRequestDocumentsModal}
        setShowModal={setShowRequestDocumentsModal}
        onConfirm={(message: string, proofs?: IProof[]) => {
          setShowRequestDocumentsModal?.(false);

          return onRequestDocuments(selectedEntryId, message, proofs);
        }}
      />
      <SplitEntryModal />
      {ivSupportForFraudDisputesFlagEnabled ? (
        <SendReportFraudModal text="Are you sure you want to continue?" />
      ) : (
        <SendReportModal />
      )}
    </>
  );
};

const SubmissionsTab: FC<SubmissionsTabProps> = ({ entries }) => {
  return (
    <>
      <EntryModals />
      <Row direction="column" gap={1}>
        <Row.Col>
          <Text variant={TextVariant.h3}>Submissions by Date</Text>
        </Row.Col>
        <Row.Col>
          <EntryList entries={entries} />
        </Row.Col>
      </Row>
    </>
  );
};

export default SubmissionsTab;
