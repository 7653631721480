// Components
import { useState } from 'react';
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import ButtonLink, {
  ButtonLinkDisabledStyle,
  ButtonLinkVariant
} from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import Row from 'src/components/atoms/Row/Row';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ConfirmResetPasswordModal } from '../ConfirmationModal';
import { Container, TextName } from './styles';

// Constants
import { ROLE_DESCRIPTION } from 'src/features/auth/constants';

// Enums
import { FeatureFlagKey, RoleEnum } from 'src/ts/enums';

// Types
import useRole from 'src/features/auth/hooks/useUserRoles';
import { useFeatureFlag } from 'src/hooks';
import { RoleType } from 'src/ts/interfaces';

export type RowUserListProps = {
  fullName: string;
  email: string;
  role: RoleType;
  settingNotification?: boolean;
  isLoadingSettingNotification?: boolean;
  companyName?: string;
  properties?: string;
  showCompanyInformation?: boolean;
  showPropertyInformation?: boolean;
  onNotification: () => void;
  onEdit: string;
  onDelete: () => void;
  isDisabledOnNotification?: boolean;
  isDisabledOnEdit?: boolean;
  isDisabledOnDelete?: boolean;
  isLoading?: boolean;
  showOnNotification?: boolean;
  showOnEdit?: boolean;
  showOnDelete?: boolean;
};

const RowUserList: React.FC<RowUserListProps> = (props: RowUserListProps) => {
  const {
    fullName,
    email,
    role,
    settingNotification,
    isLoadingSettingNotification,
    companyName,
    properties,
    showCompanyInformation,
    showPropertyInformation,
    onNotification,
    onEdit,
    onDelete,
    isDisabledOnNotification,
    isDisabledOnEdit,
    isDisabledOnDelete,
    isLoading,
    showOnNotification,
    showOnEdit,
    showOnDelete
  } = props;

  const { isAdminOrAccountRepresentative } = useRole();

  const resetPasswordFeatureFlagEnabled = useFeatureFlag(FeatureFlagKey.RESET_PASSWORD_OPTION);

  const showResetPasswordButton = resetPasswordFeatureFlagEnabled && isAdminOrAccountRepresentative;

  const CompanyInformation = () => {
    if (!companyName || !showCompanyInformation) return null;

    return (
      <>
        <Gap height={0.5} />
        <Row alignItems="flex-start" className="row__icon_text">
          <Icon color="primary" icon="company" />
          <Text variant={TextVariant.small}>{companyName}</Text>
        </Row>
      </>
    );
  };

  const PropertyInformation = () => {
    if (!showPropertyInformation) return null;

    return (
      <>
        <Gap height={0.5} />
        <Row alignItems="flex-start" className="row__icon_text row__text_property">
          <Icon color="primary" icon="property" />
          {properties !== '' ? (
            <Text variant={TextVariant.small}>{properties}</Text>
          ) : (
            <Text variant={TextVariant.small} color={TextColor.gray300}>
              No properties assigned
            </Text>
          )}
        </Row>
      </>
    );
  };

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  return (
    <>
      <Line height="tiny" />
      <Container>
        <Row justify="space-between">
          <Row.Col size={'80%'}>
            <>
              <TextName isBold variant={TextVariant.normal}>
                {fullName}
              </TextName>
              <Gap height={0.5} />
              <Text variant={TextVariant.normal}>{email}</Text>
            </>
          </Row.Col>
          <Row.Col size={'auto'} alignSelf="flex-start">
            <Row className={'buttons'} justify={'flex-end'}>
              {showOnNotification && (
                <Button
                  variant={ButtonVariant.ghost}
                  onClick={onNotification}
                  name="notification_button"
                  size={ButtonSize.medium}
                  isDisabled={isDisabledOnNotification || isLoadingSettingNotification}
                  disabledStyle={ButtonDisabledStyle.white}
                  isLoading={isLoadingSettingNotification}
                >
                  {!isLoadingSettingNotification && (
                    <Icon color="primary" icon={settingNotification ? 'bell' : 'bellCanceled'} />
                  )}
                </Button>
              )}
              {showOnEdit && (
                <ButtonLink
                  variant={ButtonLinkVariant.ghost}
                  to={onEdit}
                  name="edit_button"
                  isDisabled={isDisabledOnEdit || isLoading}
                  disabledStyle={ButtonLinkDisabledStyle.white}
                >
                  <Icon color="primary" icon="edit" />
                </ButtonLink>
              )}
              {showOnDelete && (
                <Button
                  variant={ButtonVariant.ghost}
                  onClick={onDelete}
                  name="remove_button"
                  isDisabled={isDisabledOnDelete || isLoading}
                  disabledStyle={ButtonDisabledStyle.white}
                >
                  <Icon color="primary" icon="delete" />
                </Button>
              )}
            </Row>
            {showResetPasswordButton && (
              <Row justify={'flex-end'}>
                <Button
                  name="reset_user_password_button"
                  variant={ButtonVariant.outline}
                  onClick={() => setShowResetPasswordModal(true)}
                >
                  Reset Password
                </Button>
              </Row>
            )}
          </Row.Col>
        </Row>
        <>
          <Gap height={1} />
          <Tag
            color={TagColor.blue}
            label={ROLE_DESCRIPTION[role] || 'Not found'}
            id="role-tag"
            showBackground
          />
          <Gap height={0.5} />
          {CompanyInformation()}
          {PropertyInformation()}
        </>
      </Container>
      <ConfirmResetPasswordModal
        showConfirmationModal={showResetPasswordModal}
        setShowConfirmationModal={setShowResetPasswordModal}
        userEmail={email}
      />
    </>
  );
};

RowUserList.defaultProps = {
  fullName: 'John Doe',
  email: 'john.doe@domain.com',
  role: RoleEnum.Admin,
  settingNotification: false,
  isLoadingSettingNotification: false,
  onNotification: () => null,
  onEdit: '',
  onDelete: () => null,
  isDisabledOnNotification: false,
  isDisabledOnEdit: false,
  isDisabledOnDelete: false,
  isLoading: false,
  showOnNotification: true,
  showOnEdit: true,
  showOnDelete: true
};

export default RowUserList;
