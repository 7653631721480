import { fdeEntryApi } from 'src/api';
import { defaultInstance } from 'src/api/instance';
import { getConfigVars } from 'src/helpers';
import { EntryRequestType, IEntry, IProof } from 'src/ts/interfaces';
const apiUrl = getConfigVars(window.location.hostname).api_url;
const minimizeProofsProps = (proofs: IProof[]) => {
  return proofs.map((proof) => {
    const { id, result, type } = proof;
    return { id, result, type };
  }) as unknown as IProof[];
};

const api = {
  getEntriesByFolderId(folderId: string) {
    return fdeEntryApi.getEntries(`folder_id=${folderId}`);
  },
  getEntryById(entryId: string) {
    return fdeEntryApi.getEntry(entryId);
  },
  updateEntry(entryId: string, entry: Partial<IEntry>) {
    return fdeEntryApi.updateEntry(entryId, { entry } as EntryRequestType);
  },
  mergeEntry(matchingEntryId: string, entryId: string) {
    return fdeEntryApi.mergeEntry(matchingEntryId, entryId);
  },
  splitEntry(entryId: string) {
    return fdeEntryApi.splitEntry(entryId);
  },
  sendReport(entryId: string, proofs?: IProof[]) {
    if (proofs) {
      return defaultInstance.post(`${apiUrl}/entries/${entryId}/send_report`, {
        proof_updates: minimizeProofsProps(proofs)
      });
    }
    return defaultInstance.post(`${apiUrl}/entries/${entryId}/send_report`);
  },
  requestDocuments(entryId: string, message: string, proofs?: IProof[]) {
    if (proofs) {
      return defaultInstance.post(`${apiUrl}/entries/${entryId}/request_documents`, {
        message,
        proof_updates: minimizeProofsProps(proofs)
      });
    }
    return defaultInstance.post(`${apiUrl}/entries/${entryId}/request_documents`, {
      message
    });
  }
};

export default api;
