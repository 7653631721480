import { PropertyFeature } from '@snapptinc/fraud-platform';
import { propertyFeatureApi } from 'src/api';
import { IPropertiesResponseWithPage } from 'src/ts/interfaces';
import { FeatureState } from 'src/ts/enums/feature';

const api = {
  getAll: async (propertyId: any) => {
    const response = await propertyFeatureApi.getFeatures(propertyId);
    return response as unknown as IPropertiesResponseWithPage;
  },
  updateFeature: async (propertyId: string, updatedFeature: PropertyFeature) => {
    let response;
    if (!updatedFeature.code) {
      return;
    }
    switch (updatedFeature.state) {
      case FeatureState.ENABLED:
        response = await propertyFeatureApi.enable(propertyId, updatedFeature.code);
        break;
      case FeatureState.DISABLED:
        response = await propertyFeatureApi.disable(propertyId, updatedFeature.code);
        break;
    }

    return response as unknown as IPropertiesResponseWithPage;
  }
};

export default api;
