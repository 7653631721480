export const cleanToEditedIvEnabled = {
  explanation: `Updating the fraud result to Edited will remove the existing Income Verification (IV) calculation, as IV is only performed on submissions with a Clean fraud ruling.`,
  boldWarning: `The result summary report will be sent with an IV result of Not Available.`
};

export const cleanToUndeterminedIvEnabled = {
  explanation: `Updating the fraud result to Insufficient Documentation will remove the existing Income Verification (IV) calculation, as IV is only performed on submissions with a Clean fraud ruling.`,
  boldWarning: `The result summary report will be sent with an IV result of Not Available.`
};

export const editedToCleanIvEnabledIvEligible = {
  explanation: ``,
  boldWarning: `Updating the fraud result will move this submission back into the queue for an Income Verification review. The updated results report will not be generated until the IV review is completed.`
};

export const cleanToEditedNoIV = {
  explanation: ``,
  boldWarning: `You are about to update the fraud ruling as 'Edited' and send the report.`
};

export const cleanToUndeterminedNoIV = {
  explanation: ``,
  boldWarning: `You are about to update the fraud ruling to ‘Insufficient Documentation’ and send the report.`
};

export const editedToCleanNoIV = {
  explanation: ``,
  boldWarning: `You are about to update the fraud ruling to “Clean” and send the report.`
};

export const rulingNotUpdated = {
  explanation:
    'No changes were made, but the leasing team will still be notified that the result summary is ready.',
  boldWarning: ''
};
