import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IProof } from 'src/ts/interfaces';

import { NAME } from './constants';
export const lastRemoteProofsAdapter = createEntityAdapter<IProof>({
  selectId: (proof) => proof.id
});
interface StateData extends EntityState<IProof> {
  status: StatusTypes;
  error?: string | null;
}

const initialState = lastRemoteProofsAdapter.getInitialState({
  status: StatusTypes.IDLE,
  error: null
});

const lastRemoteProofsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    },
    updateLastRemoteProofs: (state: StateData, action: PayloadAction<IProof[]>) => {
      lastRemoteProofsAdapter.upsertMany(state, action.payload);
    }
  }
});
export const selectors = {
  ...lastRemoteProofsAdapter.getSelectors((state: RootState) => state[NAME]),
  status: (state: RootState) => state[NAME].status
};

export const { reducer, actions } = lastRemoteProofsSlice;
