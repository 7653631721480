import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PropertyFeature } from '@snapptinc/fraud-platform';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { useFolderById } from 'src/features/folders/hooks';
import { useLocalProofs } from 'src/features/proofs/hooks';
import { selectors as propertyFeatureSelectors } from 'src/features/propertyFeature/propertyFeatureSlice';
import { useFeatureFlag } from 'src/hooks';
import { Feature, FeatureFlagKey, FeatureState } from 'src/ts/enums';
import { reasons } from './constants';
import { RequestDocumentsData, RequestDocumentsModalProps } from './types';

function isIvEnabled(propertyFeatures: PropertyFeature[]) {
  const iv = propertyFeatures.find((feature) => feature.code === Feature.INCOME_VERIFICATION);

  return iv?.state === FeatureState.ENABLED;
}

export const RequestDocument: FC<{ showForm: boolean; note?: string }> = ({ note, showForm }) => {
  const [applicantName, setApplicantName] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const ivSupportForFraudDisputesFlagEnabled = useFeatureFlag(
    FeatureFlagKey.IV_SUPPORT_FOR_FRAUD_DISPUTES
  );
  const propertyFeatures = useSelector(propertyFeatureSelectors.getAll.data);

  const ivEnabled = isIvEnabled(propertyFeatures || []);

  const { folderId } = useParams();
  const { folder } = useFolderById(folderId as string);

  useEffect(() => {
    if (folder) {
      setApplicantName(folder?.name || '');
      setPropertyName(folder?.property?.name || '');
    }
  }, [folder]);

  const ivEnabledText = (
    <>
      <Text variant={TextVariant.h3} isBold>
        Are you sure you want continue?
      </Text>
      <Text variant={TextVariant.small}>
        {applicantName}, {propertyName}
      </Text>
      <Text color={TextColor.gray600} variant={TextVariant.h4}>
        Updating the fraud result to Insufficient Documentation will remove the existing Income
        Verification (IV) calculation, as IV is only performed on submissions with a Clean fraud
        ruling.
      </Text>
      <Text isBold>
        The applicant will be sent a message requesting new documents and the IV result will be
        updated to Not Available.
      </Text>
    </>
  );

  const ivNotEnabledText = (
    <>
      <Text variant={TextVariant.h3} isBold>
        Are you sure you want continue?
      </Text>
      <Text variant={TextVariant.small}>
        {applicantName}, {propertyName}
      </Text>
      <Text isBold>
        You are about to update the fraud ruling to ‘Insufficient Documentation’ and send the
        report.
      </Text>
    </>
  );
  const legacyText = (
    <Text color={TextColor.gray600} variant={TextVariant.h4}>
      An email will be sent to the applicant with the following message:
      <br /> <br />
      {note}
    </Text>
  );

  const getTextComponent = () => {
    if (ivSupportForFraudDisputesFlagEnabled) {
      if (ivEnabled) {
        return ivEnabledText;
      } else {
        return ivNotEnabledText;
      }
    }
    return legacyText;
  };

  if (showForm) {
    return (
      <InputText
        placeholder="Enter custom note"
        name="note"
        type={InputTextType.text}
        config={{
          required: 'Note is required',
          validate: {
            isEmpty: (value) => value?.trim().length || 'Note contains only whitespace'
          }
        }}
        showLabel={false}
        multiline
      />
    );
  }

  return getTextComponent();
};

const RequestDocumentsModal: FC<RequestDocumentsModalProps> = ({
  showModal,
  setShowModal,
  isLoadingButtonConfirm,
  onConfirm,
  onCancel
}) => {
  const [data, setData] = useState(reasons);
  const [step, setStep] = useState<'selectReason' | 'requestDocument'>('selectReason');
  const methods = useForm<RequestDocumentsData>({
    mode: 'onChange',
    defaultValues: {
      note: ''
    }
  });

  const reason = useMemo(() => data?.find((item) => item?.isChecked)?.value, [data]);
  const showForm = reason === 'Custom';

  const { proofs } = useLocalProofs();

  const resetAll = () => {
    methods.reset();
    methods.clearErrors();
    setStep('selectReason');
    setData(reasons);
  };

  const ivSupportForFraudDisputesFlagEnabled = useFeatureFlag(
    FeatureFlagKey.IV_SUPPORT_FOR_FRAUD_DISPUTES
  );
  const modalProps = {
    selectReason: {
      title: 'Please select a reason',
      labelButtonConfirm: 'Continue',
      isDisabledButtonConfirm: !reason,
      onConfirm: () => setStep('requestDocument')
    },
    requestDocument: {
      title: '',
      labelButtonConfirm: 'Yes, Continue',
      isDisabledButtonConfirm: showForm && !methods.formState.isDirty && !methods.watch('note'),
      isLoadingButtonConfirm,
      onConfirm: () => {
        if (ivSupportForFraudDisputesFlagEnabled) {
          if (showForm) {
            methods.handleSubmit(({ note }) => onConfirm?.(note, proofs))();
          } else {
            onConfirm?.(reason, proofs);
          }
        } else {
          if (showForm) {
            methods.handleSubmit(({ note }) => onConfirm?.(note))();
          } else {
            onConfirm?.(reason);
          }
        }
        resetAll();
      }
    }
  };

  return (
    <Modal
      variant={ModalVariant.none}
      labelButtonCancel={ivSupportForFraudDisputesFlagEnabled ? 'Go back' : 'Cancel'}
      width={ModalWidth.small}
      showModal={showModal}
      setShowModal={setShowModal}
      isBackClosable={false}
      {...modalProps[step]}
      onCancel={() => {
        resetAll();
        onCancel?.();
      }}
    >
      <Row direction="column" gap={1}>
        {step === 'selectReason' ? (
          <>
            <Text color={TextColor.gray600}>
              Indicates to the applicant the reason why additional documents are being requested.
            </Text>
            <RadioButtonGroup name="checkbox-group-reason" data={data} onChange={setData} />
          </>
        ) : (
          <FormProvider {...methods}>
            <RequestDocument note={reason} showForm={showForm} />
          </FormProvider>
        )}
      </Row>
    </Modal>
  );
};

export default RequestDocumentsModal;
