// Vendor
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import {
  getIncomeVerificationProofsByEntryIdThunk,
  getProofsByEntryIdThunk
} from 'src/features/proofs/services';
import { RootState } from 'src/store';
import { selectors } from '../proofsSlice';

// Enums
import { Status } from 'src/ts/enums';

export const useProofsFilteredByEntryId = (entryId: string, incomeVerification = false) => {
  const dispatch = useDispatch();
  const proofs = useSelector((state: RootState) => selectors.selectAllByEntryId(state, entryId));
  const status = useSelector(selectors.status);

  useEffect(() => {
    if (entryId && status === Status.IDLE) {
      if (incomeVerification) {
        dispatch(getIncomeVerificationProofsByEntryIdThunk(entryId));
      } else {
        dispatch(getProofsByEntryIdThunk(entryId));
      }
    }
  }, [entryId, status, incomeVerification, dispatch]);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    proofs,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};

export const useLocalProofs = () => {
  const proofs = useSelector((state: RootState) => selectors.selectAll(state));
  const status = useSelector(selectors.status);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    proofs,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};
