import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { fetchAppVersion } from 'src/features/appVersion';
import { actions as authActions, selectors as authSelectors } from 'src/features/auth/authSlice';
import { fetchCurrentUser } from 'src/features/auth/services';
import { getFeatureFlagIsIdle, getFeatureFlagIsLoading } from 'src/features/featureFlag';
import { useGetFeatureFlag } from 'src/features/featureFlag/hooks/useGetFeatureFlag';
import { selectors as propertySelectors } from 'src/features/property';
import { configVars } from 'src/helpers';
import Router from 'src/router';
import { useAppDispatch } from 'src/store';
import { FeatureFlagKey } from 'src/ts/enums';
import { authApi } from './api';
import useProperty from './features/property/hooks/useProperty';

const COOKIE_MIGRATE_KEY = 'fraud_platform_cookie_migrate';
const isCookieMigrated = () => localStorage.getItem(COOKIE_MIGRATE_KEY) != null;
const markMigrated = () => localStorage.setItem(COOKIE_MIGRATE_KEY, Date.now().toString());

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const userData = useSelector(authSelectors.user);
  const userIsIdle = useSelector(authSelectors.isIdle);
  const userIsLoading = useSelector(authSelectors.isLoading);

  const featureFlagIsIdle = useSelector(getFeatureFlagIsIdle);
  const featureFlagIsLoading = useSelector(getFeatureFlagIsLoading);

  const { onGetIdentityVerificationPropertyEnabled } = useProperty();

  const getIdentityVerificationPropertyEnabledIsIdle = useSelector(
    propertySelectors.hasIdentityVerificationPropertyEnabled.isIdle
  );
  useEffect(() => {
    if (userData && getIdentityVerificationPropertyEnabledIsIdle) {
      onGetIdentityVerificationPropertyEnabled();
    }
  }, [
    userData,
    getIdentityVerificationPropertyEnabledIsIdle,
    onGetIdentityVerificationPropertyEnabled
  ]);

  const hasIdentityVerificationPropertyEnabled = useSelector(
    propertySelectors.hasIdentityVerificationPropertyEnabled.data
  );

  useEffect(() => {
    const authRefresh = async () => {
      if (userData && !isCookieMigrated()) {
        const { pathname } = window.location;
        markMigrated();
        if (pathname === '/login') {
          dispatch(authActions.logout());
        } else {
          await authApi.refreshSession();
          dispatch(fetchCurrentUser({ isRefreshSessionCaller: true }));
        }
      }
    };
    authRefresh().catch(console.error);
  }, [userData, dispatch]);

  useEffect(() => {
    if (userIsIdle && !userData) {
      dispatch(fetchCurrentUser());
      dispatch(fetchAppVersion());
    }
  }, [userIsIdle, userData, dispatch]);

  const { onGetFeatureFlagValue } = useGetFeatureFlag();

  useEffect(() => {
    if (!userIsIdle && !userIsLoading && userData) {
      onGetFeatureFlagValue(FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.EXTENDED_FA_DOCUMENT_TYPES, `role=${userData.role}`);

      onGetFeatureFlagValue(
        FeatureFlagKey.MONTHLY_GROSS_INCOME_FDE_INBOX_COLUMN,
        `role=${userData.role}`
      );

      onGetFeatureFlagValue(FeatureFlagKey.EPA_DOCUMENT_DOWNLOAD, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.DOCUMENT_TYPE_IV_FLOW_DROPDOWN, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.PAYSTUB_AND_EARNINGS_RENAME, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.IDV_ONLY_WEB_PORTAL, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.HEAP_IDENTITY, `role=${userData.role}`);
      onGetFeatureFlagValue(
        FeatureFlagKey.EDITED_RESULT_REASONS_EXTENSION,
        `role=${userData.role}`
      );
      onGetFeatureFlagValue(FeatureFlagKey.RESET_PASSWORD_OPTION, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.IV_SUPPORT_FOR_FRAUD_DISPUTES, `role=${userData.role}`);
    }
  }, [userIsIdle, userIsLoading, userData, onGetFeatureFlagValue]);

  useEffect(() => {
    const { pathname } = window.location;

    const isDocumentPortal = pathname.includes('application/apply');
    if (isDocumentPortal) {
      const favicon = document.getElementById('favicon') as HTMLAnchorElement;
      favicon.href = '/assets/images/DocumentIcon.svg';
      document.title = 'Document Portal';
    }
  });

  //Ensure load of the data required for the nav options to render the ID Verification Link tab correctly
  if (
    ((userIsIdle || userIsLoading) && (featureFlagIsIdle || featureFlagIsLoading)) ||
    (!userIsIdle && !userIsLoading && userData && hasIdentityVerificationPropertyEnabled === null)
  ) {
    return null;
  }

  return (
    <>
      {configVars.heap.app_id && (
        <HelmetProvider>
          <Helmet>
            <script type="text/javascript">
              {`window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","identifyHashed","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
heap.load("${configVars.heap.app_id}");`}
            </script>
          </Helmet>
        </HelmetProvider>
      )}
      <Router />
    </>
  );
};

export default App;
