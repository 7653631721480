// Components
import { ListView } from 'src/components/templates';
import { FDEInboxToolbar, FolderList, FolderListPagination } from './components';

// Provider
import { HasPropertyProvider } from 'src/context/HasProperty';

// Hooks
import { useSelector } from 'react-redux';
import { MultiTab } from 'src/components/molecules/MultiTab';
import {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import useRole from 'src/features/auth/hooks/useUserRoles';
import { selectors as fdeInboxSelectors } from 'src/features/fdeInbox/fdeInboxSlice';

import { useMemo } from 'react';
import { useSetFdeInbox } from 'src/features/fdeInbox';
import useHideDocLinks from 'src/features/fdeInbox/hooks/useHideDocLinks';
import { selectors as propertySelectors } from 'src/features/property';
import { FDEInboxIdentityVerificationToolbar } from 'src/pages/FDEInboxPage/components/FDEInboxIdentityVerificationToolbar';
import IdentityVerificationList from 'src/pages/FDEInboxPage/components/IdentityVerificationList';
import IdentityVerificationPagination from 'src/pages/FDEInboxPage/components/IdentityVerificationPagination';
import { fdeInboxTabs } from 'src/ts/enums';

const FDEInboxPage = () => {
  const { isLeasingTeam, isAdminOrAccountRepresentative } = useRole();
  const { selectedFdeInboxTab } = useSelector(fdeInboxSelectors);
  const { setSelectedTab } = useSetFdeInbox();

  const hasIdentityVerificationPropertyEnabled = useSelector(
    propertySelectors.hasIdentityVerificationPropertyEnabled.data
  );
  const { hideDocumentsTab } = useHideDocLinks();

  const getFilters = useMemo(() => {
    // Users Filter
    if (selectedFdeInboxTab === fdeInboxTabs.DOCUMENTS && !hideDocumentsTab) {
      return <FDEInboxToolbar />;
    }
    return <FDEInboxIdentityVerificationToolbar />;
  }, [selectedFdeInboxTab, hideDocumentsTab]);

  const isIDVViewable = useMemo(() => {
    return hasIdentityVerificationPropertyEnabled &&
      (isAdminOrAccountRepresentative || isLeasingTeam)
      ? true
      : false;
  }, [hasIdentityVerificationPropertyEnabled, isAdminOrAccountRepresentative, isLeasingTeam]);

  return (
    <HasPropertyProvider title="FDE Inbox" checkable={isLeasingTeam}>
      {isIDVViewable && (
        <ListView title="FDE Inbox" filters={getFilters}>
          <MultiTab
            name="fdeInboxTab"
            selectedIndex={selectedFdeInboxTab}
            hasSpacing
            onSelect={(index) => setSelectedTab(index as number)}
          >
            <TabHeader aria-label="FDE Inbox">
              {hideDocumentsTab ? null : <TabTitle>Documents</TabTitle>}
              <TabTitle>Identification</TabTitle>
            </TabHeader>
            <TabBody>
              {hideDocumentsTab ? null : (
                <TabContent>
                  <FolderList />
                  <FolderListPagination />
                </TabContent>
              )}
              <TabContent>
                <IdentityVerificationList />
                <IdentityVerificationPagination />
              </TabContent>
            </TabBody>
          </MultiTab>
        </ListView>
      )}
      {!isIDVViewable && (
        <ListView title="FDE Inbox" filters={<FDEInboxToolbar />}>
          <FolderList />
          <FolderListPagination />
        </ListView>
      )}
    </HasPropertyProvider>
  );
};

export default FDEInboxPage;
