// Vendor
import { FC } from 'react';

// Components
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { ProofListTable, ProofListTableProps } from 'src/components/folders/ProofListTable';
import { ShowState } from 'src/components/molecules/ShowState';
import { BulkSelectDoc } from 'src/pages/ReviewPage/components/BulkSelectDoc';
import FooterActions from './components/FooterActions';

// Hooks
import { useFolderPage } from 'src/components/folders/FolderPageContext';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useEntryById } from 'src/features/entries/hooks';
import { useProofUpdateNote, useProofUpdateState } from 'src/features/proofs/hooks';
import { useFeatureFlag } from 'src/hooks';
import { IFormValues } from 'src/pages/ReviewPage/components/BulkSelectDoc/BulkSelectDoc';
import { FeatureFlagKey } from 'src/ts/enums';

const ProofList: FC<Pick<ProofListTableProps, 'proofs'>> = ({ proofs }) => {
  const { selectedEntryId, handleDownload, handleTextFields, handleVisibility } = useFolderPage();
  const { entry } = useEntryById(selectedEntryId as string);
  const document_reviews = entry?.document_reviews || [];

  const { onUpdateNote } = useProofUpdateNote();
  const { isAccountRepresentative } = useUserRoles();

  const {
    onCheckProofs,
    onCheckProof,
    getCheckedAll,
    getIsLoading,
    onUpdateLocalProofState,
    onUpdateSelected
  } = useProofUpdateState();

  const ivSupportForFraudDisputesFlagEnabled = useFeatureFlag(
    FeatureFlagKey.IV_SUPPORT_FOR_FRAUD_DISPUTES
  );
  const updateLocalProofValues = (formData: IFormValues) => {
    proofs.forEach((proof) => {
      if (proof.isChecked) {
        onUpdateLocalProofState(proof.id, formData);
      }
    });
  };

  if (!proofs?.length && !document_reviews.length) {
    return (
      <Card>
        <Gap height={2.75} />
        <ShowState
          variant="empty"
          type="application"
          message="There are no documents <br /> available to show"
        />
      </Card>
    );
  }

  return (
    <Row direction="column" gap={1}>
      {!isAccountRepresentative && (
        <Row.Col>
          <BulkSelectDoc
            isDisabled={getIsLoading(proofs)}
            markSelectAll={getCheckedAll(proofs)}
            onGetCheck={({ selectAll }) => onCheckProofs(selectAll)}
            onApply={(formData) =>
              ivSupportForFraudDisputesFlagEnabled
                ? updateLocalProofValues(formData)
                : onUpdateSelected(formData, proofs)
            }
          />
        </Row.Col>
      )}
      <Row.Col>
        <Line />
      </Row.Col>
      <Row.Col>
        <ProofListTable
          entry={entry}
          proofs={proofs}
          documentReviews={document_reviews}
          onDownload={handleDownload}
          onSaveNote={onUpdateNote}
          onTextFields={handleTextFields}
          onVisibility={handleVisibility}
          onCheckbox={({ value: id, isChecked }) => onCheckProof(id, isChecked)}
        />
      </Row.Col>
      <Row.Col>
        <FooterActions proofs={proofs} />
      </Row.Col>
    </Row>
  );
};

export default ProofList;
