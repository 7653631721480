// Vendor
import PropTypes from 'prop-types';

// Components
import { ButtonColor } from 'src/components/atoms/Button';
import { ButtonLinkColor } from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import Grid from 'src/components/atoms/Grid';
import { Line } from 'src/components/atoms/Line';
import Text, { TextAlignItems, TextVariant } from 'src/components/atoms/Text';
import CopyButton from 'src/components/molecules/CopyButton';
import { YardiLogo } from 'src/components/molecules/YardiLogo';
import { StyledButtonContainer, StyledContainer, StyledGrid } from './styles';

// Helpers
import { getPrivateLink, getPublicLink } from 'src/features/DUP/helpers';

// Types
import useHideDocLinks from 'src/features/fdeInbox/hooks/useHideDocLinks';
import { IProperty } from 'src/ts/interfaces';

export type DocumentLinkRowProps = Pick<
  IProperty,
  | 'name'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'short_id'
  | 'company_short_id'
  | 'is_linked_to_yardi'
  | 'disable_dup_fd'
>;

const DocumentLinkRow: React.FC<DocumentLinkRowProps> = (props) => {
  const {
    company_short_id,
    short_id: propertyShortId,
    is_linked_to_yardi,
    disable_dup_fd: idDupDisabled
  } = props;
  const { hideDocumentLinks } = useHideDocLinks();

  if (idDupDisabled || hideDocumentLinks) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        <Line height="tiny" />
        <Gap height={1} />
        <StyledGrid direction="column">
          <Grid justify="space-between">
            <Text isBold alignItems={TextAlignItems.center} color={'initial'}>
              {props.name}
            </Text>
            <YardiLogo isYardiActivated={Boolean(is_linked_to_yardi)} />
          </Grid>
          <Gap height={0.1875} />
          <Text variant={TextVariant.small} color={'gray700'}>
            {`${props.address}, ${props.city}, ${props.state} ${props.zip}`}
          </Text>
          <Gap height={1} />
          <StyledButtonContainer>
            <CopyButton
              to={getPublicLink(propertyShortId || '', company_short_id || '')}
              label="Applicants Link"
              showCopyButton
            />
            <CopyButton
              to={getPrivateLink(propertyShortId || '', company_short_id || '')}
              label="Leasing Team Link"
              colorLink={ButtonLinkColor.secondary}
              colorCopy={ButtonColor.secondary}
            />
          </StyledButtonContainer>
        </StyledGrid>
      </StyledContainer>
    </>
  );
};

DocumentLinkRow.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  short_id: PropTypes.string,
  company_short_id: PropTypes.string.isRequired,
  is_linked_to_yardi: PropTypes.bool.isRequired,
  disable_dup_fd: PropTypes.bool
};

export default DocumentLinkRow;
