import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';
import { IProof } from 'src/ts/interfaces';
import { actions, selectors } from './remoteProofsSlice';

export const useRemoteProofs = () => {
  const dispatch = useDispatch();
  const proofs = useSelector((state: RootState) => selectors.selectAll(state));
  const status = useSelector(selectors.status);
  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;
  const updateLastRemoteProofs = (proofs: IProof[]) => {
    dispatch(actions.updateLastRemoteProofs(proofs));
  };
  return {
    proofs,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    updateLastRemoteProofs
  };
};
