import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP/constants';

const defaultInstance: AxiosInstance = axios.create({ withCredentials: true });
const unauthenticatedSessionInstance: AxiosInstance = axios.create({ withCredentials: true });

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // NOTE: By sending response.data we are missing extra information of each request like
  // `headers`, status, statusText. This is a `wontFix` for now because we need to change the
  // redux slices

  // TODO: In order to send the Axios `response` each API file, inside the feature folder, need to return `response.data`(The line below) instead of only response
  return response.data;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // A console error can be added if we want an alert in datadog for every response failure
  // sending the complete axios Error without losing context of the http error
  return Promise.reject(error);
};

defaultInstance.interceptors.response.use(onResponse, onResponseError);
unauthenticatedSessionInstance.interceptors.response.use(onResponse, onResponseError);
unauthenticatedSessionInstance.interceptors.request.use((config) => {
  config.headers.common['x-unauthenticated-session-token'] = localStorage.getItem(
    UNAUTHENTICATED_SESSION_TOKEN_KEY
  );
  return config;
});
const isAxiosError = axios.isAxiosError;
export { defaultInstance, isAxiosError, unauthenticatedSessionInstance };
export type { AxiosError };
