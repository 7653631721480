import useRole from 'src/features/auth/hooks/useUserRoles';
import useUsers from 'src/features/users/hooks/useUsers';
import { useFeatureFlag } from 'src/hooks';
import { Feature, FeatureFlagKey } from 'src/ts/enums';

// Logic for users whose properties all have identity_verification_only_web_portal enabled
// Refer to https://snappt.atlassian.net/browse/EEBT-1543 and https://snappt.atlassian.net/browse/ITL-1693
const useHideDocLinks = () => {
  const { userResponse } = useUsers();
  const { isLeasingTeam } = useRole();

  const features_enabled_in_all_user_properties =
    userResponse?.features_enabled_in_all_user_properties || [];

  const idvOnlyWebPortalFeatureEnabled = useFeatureFlag(FeatureFlagKey.IDV_ONLY_WEB_PORTAL);

  const hideDocumentsTab =
    idvOnlyWebPortalFeatureEnabled &&
    features_enabled_in_all_user_properties?.includes(
      Feature.IDENTITY_VERIFICATION_ONLY_WEB_PORTAL
    ) &&
    isLeasingTeam;

  return {
    hideDocumentsTab,
    hideDocumentLinks: hideDocumentsTab
  };
};

export default useHideDocLinks;
