import { Feature, FeatureState } from 'src/ts/enums';
import { IPropertyFeature } from 'src/ts/interfaces';

export const NAME = 'propertyFeature';

export const DEFAULT_PROPERTY_FEATURES: IPropertyFeature[] = [
  {
    name: 'Income Verification',
    code: Feature.INCOME_VERIFICATION,
    state: FeatureState.DISABLED
  },
  {
    name: 'Connected Payroll',
    code: Feature.PAYROLL_LINKING,
    state: FeatureState.DISABLED
  },
  {
    name: 'Identity Verification Only Web Portal',
    code: Feature.IDENTITY_VERIFICATION_ONLY_WEB_PORTAL,
    state: FeatureState.DISABLED
  }
];
