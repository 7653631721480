export enum Feature {
  INCOME_VERIFICATION = 'income_verification',
  PAYROLL_LINKING = 'payroll_linking',
  IDENTITY_VERIFICATION_ONLY_WEB_PORTAL = 'identity_verification_only_web_portal'
}

export enum FeatureState {
  DISABLED = 'disabled',
  ENABLED = 'enabled'
}
