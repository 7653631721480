import { useEffect, useMemo, useState } from 'react';

import Modal, { ModalVariant } from 'src/components/organisms/Modal';

import { EntryResultEnum, PropertyFeature } from '@snapptinc/fraud-platform';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Gap from 'src/components/atoms/Gap';
import {
  cleanToEditedIvEnabled,
  cleanToEditedNoIV,
  cleanToUndeterminedIvEnabled,
  cleanToUndeterminedNoIV,
  editedToCleanIvEnabledIvEligible,
  editedToCleanNoIV,
  rulingNotUpdated
} from 'src/constants/reportModal';
import { useEntryById, useSendReport } from 'src/features/entries/hooks';
import { useFolderById } from 'src/features/folders/hooks';
import { eligibleDocuments } from 'src/features/incomeVerification';
import { useRemoteProofs } from 'src/features/lastRemoteProofs';
import { useLocalProofs } from 'src/features/proofs/hooks';
import { selectors as propertyFeatureSelectors } from 'src/features/propertyFeature/propertyFeatureSlice';
import { FeatureState, ProofResultEnum } from 'src/ts/enums';
import Text, { TextVariant } from '../../atoms/Text';
import { useFolderPage } from '../FolderPageContext';
import { DocumentOrProof, TypedDocumentOrProof } from '../types';

interface ModalText {
  explanation: string;
  boldWarning: string;
}

function ivIsEnabled(propertyFeatures: PropertyFeature[]) {
  const iv = propertyFeatures.find((feature) => feature.code === 'income_verification');

  return iv?.state === FeatureState.ENABLED;
}

function containsIVEligibleDocuments(proofs: TypedDocumentOrProof[]) {
  let hasAtLeastOneDoc = false;

  for (let i = 0; i < proofs.length; i++) {
    if (eligibleDocuments.find((type) => type === proofs[i].type)) {
      hasAtLeastOneDoc = true;
      break;
    }
    if (proofs[i].type == 'CONNECTED_PAYROLL') {
      hasAtLeastOneDoc = true;
      break;
    }
  }

  return hasAtLeastOneDoc;
}

function determineModalText(
  remoteFraudRuling: ProofResultEnum,
  localProofs: TypedDocumentOrProof[],
  remoteProofs: TypedDocumentOrProof[],
  ivEnabled: boolean
): ModalText {
  const localFraudRuling = getLocalRuling(localProofs);
  const ivEligibleDocumentsInLocalProofs = containsIVEligibleDocuments(localProofs);
  const ivEligibleDocumentsInRemoteProofs = containsIVEligibleDocuments(remoteProofs);

  if (
    localFraudRuling == EntryResultEnum.Clean &&
    remoteFraudRuling == EntryResultEnum.Clean &&
    ivEligibleDocumentsInLocalProofs &&
    !ivEligibleDocumentsInRemoteProofs
  ) {
    return getNewlyIVEligibleText(ivEnabled);
  }

  if (localFraudRuling === remoteFraudRuling) return rulingNotUpdated;
  if (remoteFraudRuling === ProofResultEnum.Pending) return { explanation: '', boldWarning: '' };

  const isUncleanToClean =
    remoteFraudRuling !== ProofResultEnum.Clean && localFraudRuling === ProofResultEnum.Clean;
  if (isUncleanToClean) {
    return getUncleanToCleanModalText(ivEnabled, ivEligibleDocumentsInLocalProofs);
  } else {
    return getCleanToUncleanModalText(ivEnabled, remoteFraudRuling, localFraudRuling);
  }
}

const getLocalRuling = (proofs: DocumentOrProof[]): ProofResultEnum => {
  if (proofs.some((proof) => proof.result === ProofResultEnum.Edited)) {
    return ProofResultEnum.Edited;
  } else if (proofs.every((proof) => proof.result === ProofResultEnum.Undetermined)) {
    return ProofResultEnum.Undetermined;
  } else {
    return ProofResultEnum.Clean;
  }
};

const getUncleanToCleanModalText = (
  ivEnabled: boolean,
  ivEligibleDocumentsInLocalProofs: boolean
): ModalText => {
  return !ivEnabled || !ivEligibleDocumentsInLocalProofs
    ? editedToCleanNoIV
    : editedToCleanIvEnabledIvEligible;
};

const getNewlyIVEligibleText = (ivEnabled: boolean): ModalText => {
  return ivEnabled ? editedToCleanIvEnabledIvEligible : rulingNotUpdated;
};

const getCleanToUncleanModalText = (
  ivEnabled: boolean,
  remoteFraudRuling: string,
  localFraudRuling: string
): ModalText => {
  const modalTextOptions: { [key: string]: ModalText } = ivEnabled
    ? {
        'CLEAN-EDITED': cleanToEditedIvEnabled,
        'CLEAN-UNDETERMINED': cleanToUndeterminedIvEnabled
      }
    : {
        'CLEAN-EDITED': cleanToEditedNoIV,
        'CLEAN-UNDETERMINED': cleanToUndeterminedNoIV
      };

  const key = `${remoteFraudRuling}-${localFraudRuling}`;
  return modalTextOptions[key] ?? { explanation: '', boldWarning: '' };
};

const SendReportFraudModal = ({
  onConfirm,
  text
}: {
  onConfirm?: () => void;
  text: string;
}): JSX.Element => {
  const [boldWarning, setBoldWarning] = useState('');
  const [explanation, setExplaination] = useState('');
  const [applicantName, setApplicantName] = useState('');
  const [propertyName, setPropertyName] = useState('');

  const { selectedEntryId, showSendReportModal, setShowSendReportModal } = useFolderPage();

  const { onSendReport } = useSendReport();

  const localproofs = useLocalProofs().proofs;
  const remoteProofs = useRemoteProofs().proofs;

  const { folderId } = useParams();
  const { folder } = useFolderById(folderId as string);
  const entry = useEntryById(localproofs[0]?.entry_id || '');

  const propertyFeatures = useSelector(propertyFeatureSelectors.getAll.data);
  const ivEnabled = ivIsEnabled(propertyFeatures || []);

  useEffect(() => {
    if (localproofs && remoteProofs && entry.entry) {
      let documentReviews: TypedDocumentOrProof[] = [];
      if (entry?.entry?.document_reviews) {
        documentReviews = entry?.entry?.document_reviews?.map((document) => {
          return { type: 'CONNECTED_PAYROLL', ...document };
        });
      }

      const localDocs: TypedDocumentOrProof[] = localproofs;
      const remoteDocs: TypedDocumentOrProof[] = remoteProofs;

      const { explanation, boldWarning } = determineModalText(
        entry.entry?.result,
        localDocs.concat(documentReviews),
        remoteDocs.concat(documentReviews),
        ivEnabled
      );

      setExplaination(explanation);
      setBoldWarning(boldWarning);
    }

    if (folder) {
      setApplicantName(folder?.name || '');
      setPropertyName(folder?.property?.name || '');
    }
  }, [localproofs, remoteProofs, folder]);

  const sendReportModalProps = useMemo(
    () => ({
      title: '',
      labelButtonConfirm: 'Yes, continue',
      labelButtonCancel: 'Go back',
      showModal: showSendReportModal,
      setShowModal: setShowSendReportModal,
      onConfirm: async () => {
        setShowSendReportModal?.(false);

        // the entry needs to be updated if
        await onSendReport(selectedEntryId, localproofs);
        if (onConfirm) {
          onConfirm();
        }
      }
    }),
    [onSendReport, selectedEntryId, setShowSendReportModal, showSendReportModal, onConfirm]
  );

  return (
    <Modal {...sendReportModalProps} variant={ModalVariant.none}>
      <Text variant={TextVariant.h3} isBold>
        Are you sure you want to continue?{' '}
      </Text>
      <Gap height={1}></Gap>
      <Text variant={TextVariant.small}>
        {applicantName}, {propertyName}
      </Text>
      {explanation !== '' && <Gap height={1}></Gap>}
      <Text>{explanation}</Text>
      {boldWarning !== '' && <Gap height={1}></Gap>}
      <Text isBold>{boldWarning}</Text>
    </Modal>
  );
};

export default SendReportFraudModal;
